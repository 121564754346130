import { NavHorizontal, NavItem } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { BrandPolicyType } from "../../shared/apiTypes/apiTypes";

type Props = {
  DomesticPolicies: JSX.Element;
  InternationalPolices: JSX.Element;
};

const BrandPoliciesNavigation: React.FC<Props> = ({
  DomesticPolicies,
  InternationalPolices,
}) => {
  const [visiblePolicies, setVisiblePolicies] = React.useState<
    Omit<BrandPolicyType, "BOTH">
  >(BrandPolicyType.DOMESTIC);

  return (
    <>
      <NavHorizontal aria-label="switch between policies for domestic and international lines">
        <NavItem
          onClick={() => setVisiblePolicies(BrandPolicyType.DOMESTIC)}
          Elem={visiblePolicies === BrandPolicyType.DOMESTIC ? "span" : "button"}
          active={visiblePolicies === BrandPolicyType.DOMESTIC}
        >
          <FormattedMessage id="general.policy.domestic" />
        </NavItem>
        <NavItem
          onClick={() => setVisiblePolicies(BrandPolicyType.INTERNATIONAL)}
          Elem={visiblePolicies === BrandPolicyType.INTERNATIONAL ? "span" : "button"}
          active={visiblePolicies === BrandPolicyType.INTERNATIONAL}
        >
          <FormattedMessage id="general.policy.international" />
        </NavItem>
      </NavHorizontal>

      {visiblePolicies === BrandPolicyType.DOMESTIC && DomesticPolicies}
      {visiblePolicies === BrandPolicyType.INTERNATIONAL && InternationalPolices}
    </>
  );
};

export default BrandPoliciesNavigation;
