@use "variables" as *;

.divider {
  visibility: hidden;
  @include on-bp(md) {
    visibility: visible;
  }
  &::after {
    content: "\a";
    white-space: pre;
    @include on-bp(md) {
      content: none;
      white-space: normal;
    }
  }
}
