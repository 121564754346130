@use "variables" as *;

.heading {
  padding-bottom: $spacing-2;
}

.subline {
  color: $icon-primary-color;
}

.text {
  margin-bottom: $spacing-4;
  max-width: 880px;
}

.headingSkeleton {
  height: 33px;
  width: 180px;
}

.sublineSkeleton {
  height: 24px;
  width: 210px;
}
