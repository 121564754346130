@use "variables" as *;

.accordion {
  border-left: none;
  border-right: none;
  scroll-margin-top: 60px;
  @include on-bp(md) {
    article {
      padding-left: $spacing-8;
    }
  }
}

.title {
  font-weight: $font-weight-bold;
  margin-bottom: 0;
}

.icon {
  margin-bottom: 2px;
}

.spinner {
  text-align: center;
  @include on-bp(md) {
    text-align: left;
  }
}
